<template>
  <transition name="modal" class="householdModal">
    <div class="vueModal" v-if="householdModal">
      <div class="vueModalOuter"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">Los gehts!</h2>
        </div>
        <div class="pad-8 t-center bgcolor-redbeige br-8" @click="joinHouseholdModal = true; householdModal = false">
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12">
              <img src="assets/images/join-household.png" class="responsive-img"/>
            </div>
            <div class="col-sp-6 row-sp-12 t-left">
              <div class="pad-16"></div>
              <h3 class="pad-16 color-red">Trete einem Haushalt bei</h3>
            </div>
          </div>
        </div>
        <div class="t-center pad-16">
          <h3>oder</h3>
        </div>
        <div class="pad-8 t-center bgcolor-redbeige br-8" @click="createHouseholdModal = true; householdModal = false">
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12 t-left">
              <div class="pad-16"></div>
              <h3 class="pad-16 color-red">Erstelle einen neuen Haushalt</h3>
            </div>
            <div class="col-sp-6 row-sp-12">
              <img src="assets/images/create-household.png" class="responsive-img"/>
            </div>
          </div>
        </div>

      </div>
    </div>
  </transition>
  <transition name="modal" class="householdModal">
    <div class="vueModal" v-if="createHouseholdModal">
      <div class="vueModalOuter"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">Erstelle einen neuen Haushalt</h2>
        </div>
        <form @submit.prevent="createHouseHold">
          <div class="pad-8 t-center bgcolor-redbeige br-8">
            <div class="grid-12">
              <div class="col-sp-3 row-sp-12">
                <div class="pad-24"></div>
                <img src="assets/images/pointing-male.png" class="responsive-img"/>
              </div>
              <div class="col-sp-9 row-sp-12 t-left pad-4">
                <div class="pad-24"></div>
                <div class="field simple">
                  <label for="householdname">Gib deinem Haushalt einen Namen</label>
                  <input type="text" id="householdname" name="householdname" placeholder="Name des Haushalts" required v-model="householdname">
                </div>
                <div class="t-right pad-16">
                  <button type="submit" class="button bgcolor-red color-white f-bold br-8" v-if="householdname.length > 0">Weiter</button>
                </div>
              </div>
            </div>
          </div>
          <div class="t-left">
            <div class="pad-16"></div>
            <button type="button" class="button bgcolor-redbeige color-black br-8" @click="createHouseholdModal = false; householdModal = true">Zurück</button>
          </div>
        </form>
      </div>
    </div>
  </transition>
  <transition name="modal" class="householdModal">
    <div class="vueModal" v-if="joinHouseholdModal">
      <div class="vueModalOuter"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">Trete einem Haushalt bei</h2>
        </div>
        <form @submit.prevent="joinHouseHold">
          <div class="pad-8 t-center bgcolor-redbeige br-8">
            <div class="grid-12">
              <div class="col-sp-3 row-sp-12">
                <div class="pad-24"></div>
                <img src="assets/images/pointing-female.png" class="responsive-img"/>
              </div>
              <div class="col-sp-9 row-sp-12 t-left pad-4">
                <div class="pad-16"></div>
                <div class="field simple">
                  <label for="invitecode">Gib deinen Einladungscode ein</label>
                  <input type="text" id="invitecode" name="invitecode" placeholder="Einladungscode" v-model="invitecode" required>
                </div>
                <p v-if="JHHStatus == 'fail'" style="color: #e74966!important;">
                  Dieser Einladungscode ist ungültig oder bereits abgelaufen. Bitte erfrage einen neuen.
                </p>
                <div class="t-right pad-16">
                  <button type="submit" class="button bgcolor-red color-white f-bold br-8"  v-if="invitecode.length > 0">Weiter</button>
                </div>
              </div>
            </div>
          </div>
          <div class="t-left">
            <div class="pad-16"></div>
            <button type="button" class="button bgcolor-redbeige color-black br-8" @click="joinHouseholdModal = false; householdModal = true">Zurück</button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "houseHoldModal",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      householdModal: false,
      joinHouseholdModal: false,
      createHouseholdModal: false,
      householdname: '',
      invitecode: '',
      JHHStatus: '',
      CHHStatus: '',
    }
  },
  mounted() {
    this.config.loading = false,
        this.checkHouseHold()
  },
  methods: {
    checkHouseHold(){
      const _this = this
      this.axios.post(this.config.projectURL + 'haushalt/checkHouseHold')
          .then((response) => {
            if(response.status === 250){
              _this.householdModal = false
            }else{
              _this.householdModal = true
            }
          })
    },
    createHouseHold(){
      const _this = this
      const fD = new FormData()
      fD.append('householdname', this.householdname)
      this.axios.post(this.config.projectURL + 'haushalt/createHouseHold', fD)
          .then((response) => {
            if(response.status === 250){
              _this.createHouseholdModal = false
              _this.CHHStatus = 'ok'
            }else{
              _this.CHHStatus = 'fail'
            }
          })
    },
    joinHouseHold(){
      const _this = this
      const fD = new FormData()
      fD.append('invite_code', this.invitecode)
      this.axios.post(this.config.projectURL + 'haushalt/joinHouseHold', fD)
          .then((response) => {
            if(response.status === 250){
              _this.JHHStatus = 'ok'
              _this.joinHouseholdModal = false
            }else{
              _this.JHHStatus = 'fail'
            }
          })
    },
  }
}
</script>

<style scoped>
.householdModal{
  position: fixed;
  z-index: 9;
}
.householdModal .vueModalInner{
  background-color: #fffbec!important;
  width: calc(100% - 32px);
  max-width: 350px;
}
.householdModal .field.simple input{
  width: calc(100% - 32px);
  margin-top: 2px;
  border-radius: 8px;
  border: none;
}
</style>