<template>
  <main id="dashboard" class="bgcolor-redbeige">
    <section class="pad-8 bgcolor-redbeige">
        <div class="pad-8 color-red" style="margin-bottom: -150px;">
          <h2>Hey {{ user.firstname }},</h2>
          <div class="pad-4"></div>
          <p>Willkommen zurück bei<br> <span style="font-family: 'Roboto';"><b>Metrie</b>Home</span>.</p>
        </div>
        <div class="f-right">
          <img src="assets/images/home-image-together.png" class="width-250" style="margin-bottom: -15px; margin-left: -15px;"/>
        </div>
      <div class="f-clear"></div>
        <div class="pad-8">
          <div class="bgcolor-beige color-grey br-16 pad-16">
            <h4>Gut zu wissen:</h4>
            <p>Du liegst aktuell 2931 Punkte hinter Robin</p>
            <div class="pad-8"></div>
            <div class="t-right">
              <button class="button f-bold bgcolor-red color-white br-8">Oki doki</button>
            </div>
          </div>
        </div>
    </section>
    <section class="pad-8 bgcolor-beige">
      <div class="grid-12">
        <div class="col-sp-8 row-sp-12">
          <div class="pad-24"></div>
          <h2 class="f-bold pad-8 color-red">Die Homewall</h2>
          <h3 class="f-bold pad-8 color-grey" style="margin-top: -16px;">Neues aus deinem Haushalt</h3>
        </div>
        <div class="col-sp-4 row-sp-12 t-right">
          <img src="assets/images/feed-female.png" class="width-100" style="margin-bottom: -35px; margin-left: -8px; position: relative; z-index: 1;"/>
        </div>
      </div>
      <div class="pad-8">
        <form @submit.prevent="wallUserPost">
          <textarea id="wallposter" placeholder="Teile etwas mit deinem Haushalt" rows="4" v-model="wallPoster"></textarea>
          <div id="actionbar" class="bgcolor-redbeige">
            <div class="grid-12">
              <div class="col-sp-6 row-sp-12 pad-4">
                <img src="assets/images/add-image.svg" class="width-32"/>
              </div>
              <div class="col-sp-6 row-sp-12 t-right pad-4">
                <button type="submit" class="button bgcolor-red color-white br-4">Posten</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
    <section class="pad-8 bgcolor-beige">
      <houseHoldPosts v-for="post in houseHoldPosts" :post="post" :key="post.id" @update="getPostsData();"/>
    </section>
    <div class="pad-40"></div>
  </main>
  <houseHoldModal/>

</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import houseHoldModal from "@/components/modals/houseHoldModal";
import houseHoldPosts from "@/components/dashboard/post";


export default {
  name: "DashBoard",
  title: "Dashboard",
  components: {
    'houseHoldModal': houseHoldModal,
    'houseHoldPosts': houseHoldPosts,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      houseHoldPosts: '',
      wallPoster: '',
      postImage: ''
    }
  },
  mounted() {
    this.config.loading = false
    this.getPostsData()
  },
  methods: {
    wallUserPost(){
      const _this = this
      const fD = new FormData()
      fD.append('wallPoster', this.wallPoster)
      fD.append('postImage', this.postImage)
      this.axios.post(this.config.projectURL + 'dashboard/createUserPost', fD)
        .then((response) => {
          if(response.status === 250){
            this.getPostsData()
            this.wallPoster = '';
            this.postImage = '';
          }
        })
    },
    getPostsData(){
      const _this = this
      this.axios.post(this.config.projectURL + 'dashboard/getPostsData')
          .then((response) => {
            if(response.status === 250){
              _this.houseHoldPosts = response.data
            }
          })
    }
  }
}
</script>

<style>
main#dashboard{
  padding-bottom: 32px!important;
  padding-top: 80px!important;
}
#wallposter{
  width: calc(100% - 16px);
  position: relative;
  z-index: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 2px solid #fde8db;
  padding: 8px;
  font-size: 0.9em;
}
#actionbar{
  width: 100%;
  border: 2px solid #fde8db;
  margin-top: -6px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.householdModal{
  position: fixed;
  z-index: 9;
}
.householdModal .vueModalInner{
  background-color: #fffbec!important;
  width: calc(100% - 32px);
  max-width: 350px;
}
.householdModal .field.simple input{
  width: calc(100% - 32px);
  margin-top: 2px;
  border-radius: 8px;
  border: none;
}

</style>