<template>
  <article class="pad-8 post" v-if="post.userPost">
    <div class="post-content bgcolor-redbeige pad-16 br-16">
      <div class="t-left">
        <h3 class="f-normal">
          <img v-if="post.img != null" :src="post.img" class="postImg"/>
          <span class="color-red f-bold">{{ post.content }}</span>
        </h3>
        <div class="pad-8"></div>
      </div>
    </div>
    <div class="post-footer grid-12">
      <div class="col-sp-6 row-sp-12">
        <div class="user">
          <img :src="'assets/images/users/'+post.userimg" class="bgcolor-redbeige image width-48 circular"/>
          <span>{{ post.firstname }}</span>
        </div>
      </div>
      <div class="col-sp-6 row-sp-12 t-right">
        <p class="meta">{{ post.timestamp }}</p>
      </div>
    </div>
  </article>
  <article class="pad-8 post" v-else>
    <div class="post-content bgcolor-redbeige pad-16 br-16">
      <div class="t-left">
        <h3 class="f-normal">
          <img v-if="post.img != null" :src="post.img" class="postImg"/>
          <span class="color-red f-bold">{{ post.content }}</span>
        </h3>
        <div class="pad-8"></div>
      </div>
    </div>
    <div class="post-footer grid-12">
      <div class="col-sp-6 row-sp-12">
        <div class="user">
          <img :src="'assets/images/users/'+post.userimg" class="bgcolor-redbeige image width-48 circular"/>
          <span>{{ post.firstname }}</span>
        </div>
      </div>
      <div class="col-sp-6 row-sp-12 t-right">
        <p class="meta">{{ post.timestamp }}</p>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "houseHoldPosts",
  props: ["post"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>
.post .post-content{
  padding-bottom: 32px!important;
}
.post .post-content h3{
  font-size: 0.9em;
}
.post .post-content h4{
  font-size: 0.8em;
}
.post .post-footer .user{
  padding-left: 8px;
  margin-top: -32px;
}
.post .post-footer .user span{
  font-size: 0.8em;
  color: #ffffff;
  font-weight: bold;
  background-color: #e74966;
  padding: 4px 8px 2px 16px;
  margin-left: -16px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-top: -4px;
}
.post .post-footer .user img{
  position: relative;
  z-index: 2;
  vertical-align: bottom;
  border: 2px solid #e74966;
  background-color: #fffbec;
}
.post .post-footer .meta{
  font-size: 0.8em;
  color: rgba(0,0,0,0.4);
  padding: 4px;
}
.post .postImg{
  max-width: 48px;
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 16px;
}
</style>