<template>
  <main id="profil" class="bgcolor-redbeige">
    <section id="profilheader">
      <img :src="'assets/images/users/' + user.avatar" class="bgcolor-redbeige image width-100 circular"/>
      <div class="profilheaderInfos">
        <h2>{{ user.firstname }}</h2>
        <h4 class="f-normal color-red">{{ user.email }}</h4>
      </div>
    </section>
    <section class="pad-16">
      <div id="profilmenu">
        <ul>
          <li>
            <router-link to="haushalt">
              Meine Haushalte
              <img src="assets/images/arrow-right.svg" class="arrow"/>
            </router-link>
          </li>
          <li>
            <router-link to="">
              Einstellungen
              <img src="assets/images/arrow-right.svg" class="arrow"/>
            </router-link>
          </li>
          <li>
            <router-link to="">
              Mein Account
              <img src="assets/images/arrow-right.svg" class="arrow"/>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section id="helpcontainer" class="pad-16">
      <div class="bgcolor-beige pad-16 br-8 t-center">
        <h4 class="color-red">Probleme?</h4>
        <p>
          Solltest du Probleme oder Fragen zur Benutzung dieser App haben, so wende dich gerne an uns unter:
          <a href="mailto:help@hkduell.com">help@metriehome.com</a>
        </p>
      </div>
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Haushalt",
  title: "Haushalt",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style>

main#profil{
  padding-bottom: 80px;
  padding-top: 0!important;
}
#profilheader{
  text-align: center;
  padding-top: 40px;
}
#profilheader img{
  border: 4px solid #ffffff;
  position: relative;
  z-index: 1;
}
#profilheader .profilheaderInfos{
  background-color: #ffffff;
  width: calc(100% - 32px);
  margin: 0 auto;
  border-radius: 8px;
  padding-top: 40px;
  position: relative;
  z-index: 0;
  margin-top: -40px;
  padding-bottom: 16px;
}
#profilmenu{
  background-color: #ffffff;
  border-radius: 8px;
}
#profilmenu ul{
  margin: 0;
  padding: 0;
}
#profilmenu ul li{
  display: block;
  list-style: none;
  clear: both;
}
#profilmenu ul li a{
  display: block;
  padding: 16px;
  text-decoration: none;
  color: #e74966;
  font-weight: bold;
  border-radius: 8px;
}
#profilmenu ul li a .arrow{
  width: 24px;
  float: right;
  margin-top: -2px;
}
#profilmenu ul li a:hover{
  transition: 0.3s;
  color: #ffffff;
  background-color: #e74966;
}
#profilmenu ul li a:hover img{
  filter: brightness(100);
}
#helpcontainer p{
  margin-top: 8px;
  font-size: 0.9em;
  color: rgba(0,0,0,0.4);
}
#helpcontainer p a{
  display: block;
  color: #e74966;
  font-weight: bold;
  margin-top: 8px;
  text-decoration: none;
}
</style>