<template>
  <div class="giftItem grid-12" v-if="reward.points <= userPoints">
    <div class="col-sp-2 row-sp-12 pad-8">
      <img :src="'assets/images/icons/'+reward.icon" class="width-100"/>
    </div>
    <div class="col-sp-10 row-sp-12 pad-8">
      <div class="pad-4"></div>
      <h4 class="color-red">{{ reward.title }}</h4>
      <p class="color-grey">{{ reward.description }}</p>
    </div>
    <div class="col-sp-12 row-sp-12 pad-8">
      <button @click="rewardId = reward.id; useReward();" class="bgcolor-red color-white f-bold button tiny">
        <img src="/assets/images/coin.svg" style=" vertical-align: -4px; width: 24px;"/> Für {{ reward.points }} Münzen einlösen
      </button>
    </div>
  </div>
  <div class="giftItem grid-12" v-else style="opacity: 0.6; ">
    <div class="col-sp-2 row-sp-12 pad-8">
      <img :src="'assets/images/icons/'+reward.icon" class="width-100"/>
    </div>
    <div class="col-sp-10 row-sp-12 pad-8">
      <div class="pad-4"></div>
      <h4 class="color-red">{{ reward.title }}</h4>
      <p class="color-grey">{{ reward.description }}</p>
    </div>
    <div class="col-sp-12 row-sp-12 pad-8">
      <button disabled class="bgcolor-red color-white f-bold button tiny">
        <img src="/assets/images/coin.svg" style=" vertical-align: -7px; width: 24px;"/> Du benötigst noch {{ reward.points - userPoints}} Münzen
      </button>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "houseHoldRewards",
  props: ["reward"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      userPoints: '',
      rewardId: ''
    }
  },
  mounted() {
    this.config.loading = false
    this.getRewardPointsById()
  },
  methods: {
    getRewardPointsById(){
      const _this = this
      this.axios.post(this.config.projectURL + 'belohnungen/getRewardPointsById')
          .then((response) => {
            if(response.status === 250){
              _this.userPoints = response.data
            }
          })
    },
    useReward(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.rewardId)
      this.axios.post(this.config.projectURL + 'belohnungen/useReward', fD)
          .then((response) => {
            if(response.status === 250){
              this.getRewardPointsById()
              this.$emit('update')
            }
          })
    }
  }
}
</script>

<style scoped>
.giftItem{
  margin-bottom: 16px;
  background-color: #fde8db;
  border-radius: 8px;
  border: 2px #e74966 dashed;
}
.giftItem span{
  font-size: 0.9em;
}
.giftItem button.button{
  padding: 6px 6px 4px 6px;
  border: 1px solid #e74966;
  width: 100%;
  margin-top: -8px;
}
.giftItem button img{
  width: 24px;
}
.giftItem button.inverted img{
  width: 24px;
}
</style>