<template>
  <main id="erfolge" class="bgcolor-redbeige">
    <section class="pad-16 t-center">
      <div class="bgcolor-beige br-16">
        <img src="assets/images/erfolge-male.png" class="responsive-img" style="margin-top: -200px; margin-bottom: -30px; max-width: 500px; margin: 0 auto; display: block;"/>
        <div class="t-left br-16 bgcolor-red color-white" style="position: relative; z-index: 2; margin-left: 150px; margin-top: -150px; margin-right: -8px; padding: 16px;">
          <h2 class="f-normal">Deine Erfolge</h2>
          <div class="pad-4"></div>
          <p class="color-white" style="font-size: 0.9em;">
            Erhalte extra <span class="f-bold">Haushaltspunkte</span> indem du Erfolge erreichst.
          </p>
          <div class="pad-4"></div>
          <div class="t-right">
            <button @click="helpModal = true" class="bgcolor-white color-red button tiny f-bold br-8">Mehr Infos</button>
          </div>
        </div>
      </div>
    </section>
    <section class="pad-8 grid-12" style="margin-top: 40px;">
      <div class="col-sp-6 row-sp-12 pad-8">
        <div class="bgcolor-white pad-16 br-8 archivmentbox active">
          <div class="t-center">
            <img src="assets/images/cook.svg" class="width-100">
            <h4>Meisterkoch</h4>
            <p>
              Koche mindestens 10 mal für deinen Haushalt.
            </p>
            <div class="pad-16">
              <span class="f-bold color-red">+ 250 Punkte</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sp-6 row-sp-12 pad-8">
        <div class="bgcolor-white pad-16 br-8 archivmentbox success">
          <div class="t-center">
            <img src="assets/images/cook.svg" class="width-100">
            <h4>Meisterkoch</h4>
            <p>
              Koche mindestens 10 mal für deinen Haushalt.
            </p>
            <div class="pad-16">
              <span class="f-bold color-red">+ 250 Punkte</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sp-6 row-sp-12 pad-8">
        <div class="bgcolor-white pad-16 br-8 archivmentbox">
          <div class="t-center">
            <img src="assets/images/cook.svg" class="width-100">
            <h4>Meisterkoch</h4>
            <p>
              Koche mindestens 10 mal für deinen Haushalt.
            </p>
            <div class="pad-16">
              <span class="f-bold color-red">+ 250 Punkte</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <transition name="modal" class="helpModal">
    <div class="vueModal" v-if="helpModal">
      <div class="vueModalOuter" @click="helpModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red"><img src="assets/images/questionmark.svg" style="vertical-align: bottom;" class="width-32"/> Erfolge</h2>
        </div>
        <div class="pad-8 t-center bgcolor-redbeige br-8">
          <div class="grid-12">
            <div class="col-sp-3 row-sp-12">
              <div class="pad-8"></div>
              <img src="assets/images/pointing-male.png" class="responsive-img"/>
            </div>
            <div class="col-sp-9 row-sp-12 t-left pad-8">
              <div class="pad-4"></div>
              <h4>Was sind Erfolge?</h4>
              <p>
                Erfolge sind Auszeichnungen, die du erreichen kannst, wenn du z. B. bestimmte Aufgaben
                oft absolvierst.<br><br>
                Für das erhalten dieser Auszeichnungen erhälst du dann neben deinen Standardpunkten noch
                weitere einmalige Zustatzpunkte.
              </p>
            </div>
          </div>
        </div>
        <div class="t-left">
          <div class="pad-16"></div>
          <button class="button bgcolor-redbeige color-black br-8" @click="helpModal = false;">Zurück</button>
        </div>
      </div>
    </div>
  </transition>
  <houseHoldModal/>

</template>
<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import houseHoldModal from "@/components/modals/houseHoldModal";


export default {
  name: "Erfolge",
  title: "Erfolge",
  components: {
    'houseHoldModal': houseHoldModal
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      helpModal: false,
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style>
.archivmentbox{
  opacity: 0.4;
  border: #ffffff 4px solid;
}
.archivmentbox.success{
  opacity: 1;
  border-color: #e74966;
}
.archivmentbox.active{
  opacity: 1;
  border-color: #e74966;
  background-color: #e74966;
  color: #ffffff;
}
.archivmentbox.active p{
  color: rgba(255,255,255,0.6);
}
.archivmentbox.active img{
  filter: brightness(100);
}
.archivmentbox.active span{
  color: #ffffff!important;
}
.archivmentbox p{
  font-size: 0.8em;
  color: rgba(0,0,0,0.6);
}
main#erfolge{
  padding-top: 0px;
  padding-bottom: 80px;
}
.helpModal .vueModalInner{
  background-color: #fffbec!important;
  width: calc(100% - 32px);
  max-width: 350px;
}
.helpModal .vueModalInner p{
  margin-top: 4px;
  color: rgba(0,0,0,0.4);
  font-size: 0.9em;
}
</style>