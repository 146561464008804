<template>
  <main id="haushalt" class="bgcolor-beige">
    <section class="t-center">
      <div class="pad-16"></div>
      <img src="assets/images/join-household.png" class="width-200"/>
    </section>
    <section id="haushaltHeader" class="pad-16" style="margin-top: -24px;">
      <div class="bgcolor-red br-8 pad-24 color-white t-center">
        <h3>{{ houseHoldName }}</h3>
      </div>
      <div class="pad-4"></div>
      <div class="grid-12">
        <div class="col-sp-6 row-sp-12 t-left">
          <button class="button bgcolor-redbeige color-red" @click="taskModal = true;">
            <img src="assets/images/todos.svg"> Aufgaben verwalten
          </button>
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <button class="button bgcolor-redbeige color-red" @click="rewardsModal = true;">
            <img src="assets/images/belohnungen.svg"> Belohnungen verwalten
          </button>
        </div>
      </div>
    </section>
    <section class="pad-16">
      <div id="UserList" class="bgcolor-white br-8 pad-16 color-red t-center">
        <h3>Deine Mitbewohner</h3>
        <div class="pad-4"></div>
        <div>
          <houseHoldUsers v-for="user in houseHoldUsers" :houseHoldUser="user" :key="user.user_id"/>
        </div>
        <div>
          <div class="pad-4"></div>
          <button class="button bgcolor-red fluid color-white f-bold t-center" @click="addUserModal = true;">
            Person einladen
          </button>
        </div>
      </div>
    </section>
  </main>
  <transition name="modal" class="taskModal">
    <div class="vueModal" v-if="taskModal">
      <div class="vueModalOuter" @click="taskModal = false; taskModalInner = true;"/>
      <div class="vueModalInner bottom">
        <div>
          <div class="t-center pad-16" v-if="taskModalInner">
            <h2 class="color-red">
              Aufgaben verwalten
            </h2>
          </div>
          <button v-if="taskModalInner" @click="taskModal = false;createTaskModal = true;" class="pad-16 color-red t-center button bgcolor-redbeige br-8" style="display: block; width: 100%;">
            Neue Aufgabe hinzufügen
          </button>
          <div class="pad-16"></div>
          <div style="overflow-y: scroll; max-height: 170px;">
            <tasks v-for="task in tasks" :task="task" :key="task.id" @taskModal="taskModalInner = false;" @taskModalTrue="taskModalInner = true;" @update="getTasksData()"/>
          </div>
          <div class="t-left" v-if="taskModalInner">
            <div class="pad-16"></div>
            <button class="button bgcolor-redbeige color-black br-8" @click="taskModal = false;">Zurück</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" class="createTaskModal">
    <div class="vueModal" v-if="createTaskModal">
      <div class="vueModalOuter" @click="createTaskModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">
            Neue Aufgabe anlegen
          </h2>
        </div>
        <form @submit.prevent="createTask">
          <div class="pad-16 bgcolor-redbeige br-16">
            <div class="field simple">
              <label for="titel">Aufgabentitel</label>
              <input type="text" id="titel" name="titel" placeholder="Aufgabentitel" required v-model="title">
            </div>
            <div class="field simple">
              <label for="points">Punkte</label>
              <input type="number" id="points" name="points" placeholder="Punkte" required v-model="points">
            </div>
            <div class="field simple">
              <label>Icon Auswählen</label>
              <div class="grid-12">
                <div class="col-sp-4 row-sp-12">
                  <img :src="'assets/images/icons/'+icon" class="width-16 bgcolor-white circular image" style="padding: 4px 8px!important;">
                </div>
                <div class="col-sp-8 row-sp-12 t-right">
                  <button type="button" class="button tiny bgcolor-red color-white" @click="createTaskModal = false; selectIconModal = true;">Icon ändern</button>
                </div>
              </div>
            </div>
          </div>
          <div class="pad-16"></div>
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12 t-left">
              <button type="button" class="button bgcolor-redbeige color-black br-8" @click="createTaskModal = false; taskModal = true;">Zurück</button>
            </div>
            <div class="col-sp-6 row-sp-12 t-right">
              <button type="submit" class="button bgcolor-red color-white br-8" v-if="title.length > 0">Erstellen</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </transition>
  <transition name="modal" class="rewardsModal">
    <div class="vueModal" v-if="rewardsModal">
      <div class="vueModalOuter" @click="rewardsModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">
            Belohnungen verwalten
          </h2>
        </div>
        <button @click="rewardsModal = false;createRewardsModal = true;" class="pad-16 color-red t-center button bgcolor-redbeige br-8" style="display: block; width: 100%;">
          Neue Belohnung hinzufügen
        </button>
        <div class="pad-16"></div>
        <div style="overflow-y: scroll; max-height: 170px;">
          <houseHoldRewards v-for="reward in houseHoldRewards" :reward="reward" :key="reward.id"/>
        </div>
        <div class="t-left">
          <div class="pad-16"></div>
          <button class="button bgcolor-redbeige color-black br-8" @click="rewardsModal = false;">Zurück</button>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" class="createRewardsModal">
    <div class="vueModal" v-if="createRewardsModal">
      <div class="vueModalOuter" @click="createRewardsModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">
            Neue Belohnung erstellen
          </h2>
        </div>
        <form @submit.prevent="createReward">
          <div class="pad-16 bgcolor-redbeige br-16">
            <div class="field simple">
              <label for="titel">Belohnungstitel</label>
              <input type="text" id="titel" name="rewardTitle" placeholder="Belohnungstitel" required v-model="rewardTitle">
            </div>
            <div class="field simple">
              <label for="description">Beschreibung</label>
              <textarea id="description" name="rewardDescription" placeholder="Beschreibung" required v-model="rewardDescription"></textarea>
            </div>
            <div class="field simple">
              <label for="points">Punkte</label>
              <input type="number" id="points" name="rewardPoints" placeholder="Punkte" required v-model="rewardPoints">
            </div>
            <div class="field simple">
              <label>Icon Auswählen</label>
              <div class="grid-12">
                <div class="col-sp-4 row-sp-12">
                  <img :src="'assets/images/icons/'+rewardIcon" class="width-16 bgcolor-white circular image" style="padding: 4px 8px!important;">
                </div>
                <div class="col-sp-8 row-sp-12 t-right">
                  <button type="button" class="button tiny bgcolor-red color-white" @click="createRewardsModal = false; selectRewardsIconModal = true;">Icon ändern</button>
                </div>
              </div>
            </div>
          </div>
          <div class="pad-16"></div>
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12 t-left">
              <button type="button" class="button bgcolor-redbeige color-black br-8" @click="createRewardsModal = false; rewardsModal = true;">Zurück</button>
            </div>
            <div class="col-sp-6 row-sp-12 t-right">
              <button type="submit" class="button bgcolor-red color-white br-8" v-if="rewardTitle.length > 0">Erstellen</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </transition>
  <transition name="modal" class="selectIconModal">
    <div class="vueModal" v-if="selectIconModal">
      <div class="vueModalOuter" @click="selectIconModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-white">
            Icon auswählen
          </h2>
        </div>
        <div class="grid-12">
          <div class="col-sp-3 row-sp-12 t-center">
            <img src="assets/images/icons/default.svg" @click="changeIcon('default.svg')" class="image circular width-32">
          </div>
          <div class="col-sp-3 row-sp-12 t-center">
            <img src="assets/images/icons/staubsauger.svg" @click="changeIcon('staubsauger.svg')" class="image circular width-32">
          </div>
        </div>
        <div class="pad-16"></div>
        <div class="t-center">
          <button type="button" class="button bgcolor-white color-red br-8" @click="selectIconModal = false; createTaskModal = true;" >Abbrechen</button>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" class="selectRewardsIconModal">
    <div class="vueModal" v-if="selectRewardsIconModal">
      <div class="vueModalOuter" @click="selectRewardsIconModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-white">
            Icon auswählen
          </h2>
        </div>
        <div class="grid-12">
          <div class="col-sp-3 row-sp-12 t-center">
            <img src="assets/images/icons/default.svg" @click="changeRewardIcon('default.svg')" class="image circular width-32">
          </div>
          <div class="col-sp-3 row-sp-12 t-center">
            <img src="assets/images/icons/staubsauger.svg" @click="changeRewardIcon('staubsauger.svg')" class="image circular width-32">
          </div>
        </div>
        <div class="pad-16"></div>
        <div class="t-center">
          <button type="button" class="button bgcolor-white color-red br-8" @click="selectRewardsIconModal = false; createRewardsModal = true;" >Abbrechen</button>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" class="addUserModal">
    <div class="vueModal" v-if="addUserModal">
      <div class="vueModalOuter" @click="addUserModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">
            Person zum Haushalt einladen
          </h2>
        </div>
        <div class="pad-8 t-center bgcolor-redbeige br-8">
          <div class="grid-12">
            <div class="col-sp-3 row-sp-12">
              <div class="pad-32"></div>
              <img src="assets/images/happy-male.png" class="responsive-img"/>
            </div>
            <div class="col-sp-9 row-sp-12 t-left pad-8">
              <div class="pad-4"></div>
              <h4>Wie lade ich eine Person ein?</h4>
              <p>
                Gebe folgenden Einladungscode an die jeweilige Person weiter:
              </p>
              <div class="field simple">
                <input type="text" readonly placeholder="Einladungscode" :value="houseHoldInviteCode">
                <p v-if="copyText === true" style="margin: -4px 0 4px 0; font-size: 0.8em;">Code in die Zwischenablage kopiert</p>
                <div class="t-right">
                  <button @click="copyToClipboard" class="button bgcolor-red f-bold tiny color-white">
                    <img src="assets/images/copy.svg" style="width: 16px; vertical-align: middle"/>
                    Code kopieren
                  </button>
                </div>
              </div>
              <div class="pad-8"></div>
              <h4>Code teilen:</h4>
              <div class="pad-4"></div>
              <a :href="'whatsapp://send?text=Hey, hier ist der Einladungslink zu meinem MetrieHome Haushalt: '+houseHoldInviteCode" class="button bgcolor-red f-bold tiny color-white t-center" style="color: #ffffff; display: block;">
                <img src="assets/images/whatsapp.svg" style="width: 18px; vertical-align: -4px"/>
                Über WhatsApp teilen
              </a>
            </div>
          </div>
        </div>
        <div class="t-left">
          <div class="pad-16"></div>
          <button class="button bgcolor-redbeige color-black br-8" @click="addUserModal = false;">Zurück</button>
        </div>
      </div>
    </div>
  </transition>
  <houseHoldModal/>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import houseHoldModal from "@/components/modals/houseHoldModal";
import houseHoldUsers from "@/components/haushalt/users";
import tasks from "@/components/haushalt/tasks"
import houseHoldRewards from "@/components/haushalt/rewards";

export default {
  name: "Haushalt",
  title: "Haushalt",
  components: {
    'houseHoldModal': houseHoldModal,
    'houseHoldUsers': houseHoldUsers,
    'houseHoldRewards': houseHoldRewards,
    'tasks': tasks,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      addUserModal: false,
      houseHoldName: '',
      houseHoldId: '',
      houseHoldUsers: '',
      houseHoldRewards: '',
      houseHoldInviteCode: '',
      taskModal: false,
      taskModalInner: true,
      rewardsModal: false,
      createTaskModal: false,
      selectIconModal: false,
      createRewardsModal: false,
      selectRewardsIconModal: false,
      tasks: '',
      title: '',
      points: 0,
      icon: 'default.svg',
      rewardTitle: '',
      rewardDescription: '',
      rewardPoints: 0,
      rewardIcon: 'default.svg',
      copyText: false
    }
  },
  mounted() {
    this.config.loading = false
    this.getHouseHoldData()
    this.getHouseHoldUsers()
    this.getTasksData()
    this.getRewardsData()
  },
  methods: {
    changeIcon(icon){
      this.icon = icon,
      this.selectIconModal = false,
      this.createTaskModal = true
    },
    changeRewardIcon(icon){
      this.rewardIcon = icon,
      this.selectRewardsIconModal = false,
      this.createRewardsModal = true
    },
    copyToClipboard(){
      const clipboardData = event.clipboardData || window.clipboardData || event.originalEvent?.clipboardData || navigator.clipboard;
      clipboardData.writeText(this.houseHoldInviteCode);
      this.copyText = true;
    },
    getRewardsData(){
      const _this = this
      this.axios.post(this.config.projectURL + 'belohnungen/getRewardsData')
          .then((response) => {
            if(response.status === 250){
              _this.houseHoldRewards = response.data
            }
          })
    },
    getHouseHoldData(){
      const _this = this
      this.axios.post(this.config.projectURL + 'haushalt/getHouseHoldData')
          .then((response) => {
            if(response.status === 250){
              _this.houseHoldName = response.data.name,
              _this.houseHoldInviteCode = response.data.inviteCode,
              _this.houseHoldId = response.data.id
            }
          })
    },
    getTasksData(){
      const _this = this
      this.axios.post(this.config.projectURL + 'haushalt/getTasksData')
          .then((response) => {
            if(response.status === 250){
                  _this.tasks = response.data
            }
          })
    },
    getHouseHoldUsers(){
      const _this = this
      this.axios.post(this.config.projectURL + 'haushalt/getHouseHoldUser')
          .then((response) => {
            if(response.status === 250){
              _this.houseHoldUsers = response.data
            }
          })
    },
    createTask(){
      const _this = this
      const fD = new FormData()
      fD.append('title', this.title)
      fD.append('points', this.points)
      fD.append('icon', this.icon)
      this.axios.post(this.config.projectURL + 'haushalt/createTask', fD)
          .then((response) => {
            if(response.status === 250){
              _this.createTaskModal = false
              _this.taskModal = true
              this.getTasksData()
            }
          })
    },
    createReward(){
      const _this = this
      const fD = new FormData()
      fD.append('title', this.rewardTitle)
      fD.append('description', this.rewardDescription)
      fD.append('points', this.rewardPoints)
      fD.append('icon', this.rewardIcon)
      this.axios.post(this.config.projectURL + 'haushalt/createReward', fD)
          .then((response) => {
            if(response.status === 250){
              _this.createRewardsModal = false
              _this.rewardsModal = true
              this.getRewardsData()
            }
          })
    },
  }
}
</script>

<style>

main#haushalt{
  padding-bottom: 80px;
  padding-top: 0!important;
}
.helpModal .vueModalInner{
  background-color: #fffbec!important;
  width: calc(100% - 32px);
  max-width: 350px;
}
.helpModal .vueModalInner p{
  margin-top: 4px;
  color: rgba(0,0,0,0.4);
  font-size: 0.9em;
}
.helpModal .vueModalInner a{
  color: #e74966;
  text-decoration: none;
  font-weight: bold;
}
#haushaltHeader button{
  width: calc(100% - 2px);
  font-size: 0.9em;
}
#haushaltHeader button img{
  width: 28px;
  display: block;
  margin: 0 auto;
  margin-bottom: 4px;
}
#UserList{
  border: 2px dashed #e74966;
}
#UserList .useritem{
  padding-bottom: 4px;
  border: 1px dashed #e74966;
  margin-top: -1px;
}
#UserList .useritem img{
  margin-top: 8px;
}
#UserList .useritem h4{
  margin-top: 16px;
}
#UserList .useritem button{
  margin-top: 11px;
  margin-right: 8px;
}
.addUserModal .field.simple input{
  border: 1px dashed #e74966;
  width: calc(100% - 16px);
  margin-top: 8px;
}
.createTaskModal .field.simple input{
  max-width: calc(100% - 16px);
}
.selectIconModal .vueModalInner{
  background-color: #e74966!important;
}
.selectIconModal .vueModalInner img.image{
  filter: brightness(100);
}
</style>