<template>
  <footer>
    <ul>
      <li>
        <router-link active-class="active" to="/dashboard">
          <img src="assets/images/home.svg">
          <span>Feed</span>
        </router-link>
      </li>
      <li>
        <router-link active-class="active" to="/todo">
          <img src="assets/images/todos.svg">
          <span>ToDo's</span>
        </router-link>
      </li>
      <li>
        <router-link active-class="active" to="/erfolge">
          <img src="assets/images/erfolge.svg">
          <span>Erfolge</span>
        </router-link>
      </li>
      <li>
        <router-link active-class="active" to="/belohnung">
          <img src="assets/images/belohnungen.svg">
          <span>Belohnungen</span>
        </router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year(){
      return (new Date()).getFullYear()
    }
  }
}
</script>

<style scoped>
footer{
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fffbec;
}
footer ul{
  margin: 0;
  padding: 0 4px;
  width: 100%;
}
footer ul li{
  margin: 0;
  padding: 0;
  display: inline-block;
  width: calc(24.99% - 3px);
  text-align: center;
  margin-right: -4px;
  cursor: pointer;
}
footer ul li a{
  display: block;
  margin: 4px auto;
  max-width: 100px;
  padding: 4px 0;
  border-radius: 8px;
  text-decoration: none;
}
footer ul li a img{
  width: 24px;
}
footer ul li a span{
  display: block;
  font-size: 0.8em;
  color: rgba(0,0,0,0.6);
}
footer ul li a.active{
  background-color: #e74966;
}
footer ul li a.active img{
  filter: brightness(100);
}
footer ul li a.active span{
  color: rgba(255,255,255,0.8);
}
</style>