<template>
  <main class="bgcolor-redbeige">
    <section class="pad-16">
      <div class="grid-12" style="margin-bottom: -35px;">
        <div class="col-sp-5 row-sp-12">
          <img src="assets/images/male-todo.png" class="width-150" style="margin-top: -4px; position: relative; z-index: 0;"/>
        </div>
        <div class="col-sp-7 row-sp-12 t-right">
          <img src="assets/images/todo-female.png" class="width-200" style="margin-top: 64px; position: relative; z-index: 2;"/>
        </div>
      </div>
      <div class="pad-32 t-center button bgcolor-red color-white" style="position: relative; z-index: 1;">
        <div>
          <img class="width-64" style="filter: brightness(100)" src="assets/images/plus-circle.svg">
        </div>
        Zusätzliche Haushaltsaufgabe abhaken
      </div>
    </section>
    <section class="pad-16 grid-12">
      <div class="col-sp-6 row-sp-12">
        <h2 class="color-red">Offene Todo's</h2>
      </div>
      <div class="col-sp-6 row-sp-12 t-right">
        <button class="button bgcolor-beige color-red" @click="createTodoModal = true;">
          <img style="width: 18px; vertical-align: top; margin-right: 8px;" src="assets/images/plus.svg">ToDo erstellen
        </button>
      </div>
    </section>
    <section class="pad-16">
      <todos v-for="todo in todos" :todo="todo" :key="todo.id" @update="getTodoData()"/>
    </section>
    <div class="pad-40"></div>
  </main>
  <transition name="modal">
    <div class="vueModal" v-if="createTodoModal">
      <div class="vueModalOuter" @click="createTodoModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">
            ToDo erstellen
          </h2>
        </div>
        <div class="pad-8 t-center bgcolor-redbeige br-8">
          <div class="grid-12">
            <div class="col-sp-9 row-sp-12 t-left pad-8">
              <div class="pad-4"></div>
              <form @submit.prevent="createTodo">
                <div class="field simple">
                  <select class="pad-8" style="max-width: 100%;" v-model="taskId">
                    <option value="" disabled hidden>Aufgabe auswählen</option>
                    <option :value="items.id" v-for="items in taskSelectionItems" :taskSelectionItems="items" :key="items.id" >{{ items.title }} ({{ items.points }} Pkt.)</option>
                  </select>
                </div>
                <div class="t-right">
                  <router-link to="haushalt">Liste verwalten</router-link>
                </div>
                <div class="field simple">
                  <textarea name="description" rows="3" placeholder="Beschreibung" v-model="description"></textarea>
                </div>
                <div class="field simple">
                  <select name="repeat" v-model="repeat">
                    <option value="once" selected>Einmalig</option>
                    <option value="daily">Täglich</option>
                    <option value="weekly">Wöchentlich</option>
                    <option value="bi-weekly">Zwei-Wöchentlich</option>
                    <option value="monthly">Monatlich</option>
                  </select>
                </div>
                <div class="t-right">
                  <button class="button bgcolor-red color-white f-bold">
                    Erstellen
                  </button>
                </div>
              </form>
            </div>
            <div class="col-sp-3 row-sp-12">
              <img src="assets/images/todo-male.png" class="responsive-img"/>
            </div>
          </div>
        </div>
        <div class="t-left">
          <div class="pad-16"></div>
          <button class="button bgcolor-redbeige color-black br-8" @click="createTodoModal = false;">Zurück</button>
        </div>
      </div>
    </div>
  </transition>
  <houseHoldModal/>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import houseHoldModal from "@/components/modals/houseHoldModal";
import todos from "@/components/todo/todos"



export default {
  name: "Todo",
  title: "Todo",
  components: {
    'houseHoldModal': houseHoldModal,
    'todos': todos,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      createTodoModal: false,
      taskSelectionItems: '',
      taskId: '',
      description: '',
      repeat: 'once',
      todos: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.getTaskSelectionItems()
    this.getTodoData()
  },
  methods: {
    getTaskSelectionItems(){
      const _this = this
      this.axios.post(this.config.projectURL + 'todo/getTaskSelectionItems')
          .then((response) => {
            if(response.status === 250){
              _this.taskSelectionItems = response.data
            }
          })
    },
    createTodo(){
      const _this = this
      const fD = new FormData()
      fD.append('taskId', this.taskId)
      fD.append('description', this.description)
      fD.append('repeat', this.repeat)
      this.axios.post(this.config.projectURL + 'todo/createTodo', fD)
          .then((response) => {
            if(response.status === 250){
              this.createTodoModal = false
              this.taskId = ''
              this.description = ''
              this.repeat = ''
              this.getTodoData()
            }
          })
    },
    getTodoData(){
      const _this = this
      this.axios.post(this.config.projectURL + 'todo/getTodosData')
          .then((response) => {
            if(response.status === 250){
              _this.todos = response.data
            }
          })
    }
  }
}
</script>

<style>
main{
  padding-top: 0px!important;
}
.vueModal .vueModalInner{
  background-color: #fffbec!important;
  width: calc(100% - 32px);
  max-width: 350px;
}
.vueModal .vueModalInner p{
  margin-top: 4px;
  color: rgba(0,0,0,0.4);
  font-size: 0.9em;
}
.vueModal .vueModalInner a{
  color: #e74966;
  text-decoration: none;
  font-weight: bold;
}
.field.simple input,
.field.simple select{
  border: none;
  border-radius: 8px;
  margin-bottom: 8px;
}
.field.simple textarea{
  width: calc(100% - 16px);
  position: relative;
  z-index: 0;
  border-radius: 8px;
  border: none;
  padding: 8px;
  font-size: 0.8em;
  background-color: rgba(255,255,255,0.4);
}
.vueModal .vueModalInner .t-right a{
  font-size: 0.8em;
  padding-bottom: 8px;
  display: block;
}
</style>