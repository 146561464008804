<template>
  <div class="giftItem grid-12">
    <div class="col-sp-2 row-sp-12 pad-8">
      <img :src="'assets/images/icons/'+myreward.icon" class="width-100"/>
    </div>
    <div class="col-sp-10 row-sp-12 pad-8">
      <div class="pad-4"></div>
      <h4 class="color-red">{{ myreward.title }}</h4>
      <p class="color-grey">{{ myreward.description }}</p>
    </div>
    <div class="col-sp-12 row-sp-12 pad-8">
      <button class="bgcolor-red color-white f-bold button tiny">
        <img src="/assets/images/coin.svg" style=" vertical-align: -7px; width: 24px;"/>{{ myreward.points }} Münzen
      </button>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "myhouseHoldRewards",
  props: ["myreward"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>
.giftItem{
  margin-bottom: 16px;
  background-color: #fde8db;
  border-radius: 8px;
  border: 2px #e74966 dashed;
}
.giftItem span{
  font-size: 0.9em;
}
.giftItem button.button{
  padding: 6px 6px 4px 6px;
  border: 1px solid #e74966;
  width: 100%;
  margin-top: -8px;
}
.giftItem button img{
  width: 24px;
}
.giftItem button.inverted img{
  width: 24px;
}
</style>