<template>
  <div class="useritem">
    <div class="grid-12">
      <div class="col-sp-2 row-sp-12 t-center">
        <img :src="'assets/images/users/'+houseHoldUser.value" class="bgcolor-redbeige width-32 image circular"/>
      </div>
      <div class="col-sp-6 row-sp-12 t-left">
        <h4>{{ houseHoldUser.firstname }}</h4>
      </div>
      <div class="col-sp-4 row-sp-12 t-right">
        <button class="button tiny bgcolor-red color-white">
          <img src="assets/images/edit-icon.svg" class="width-16 image" style="margin-top: 0;">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "houseHoldUsers",
  props: ["houseHoldUser"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>
</style>