import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import dashBoard from "@/views/dashBoard";
import todo from "@/views/todo";
import haushalt from "@/views/haushalt";
import profil from "@/views/profil";
import erfolge from "@/views/erfolge";
import belohnung from "@/views/belohnung";
import login from "@/views/login";
import register from "@/views/register";
import navBar from "@/components/navBar/navBar";
import footer from "@/components/footer/footer";

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: login
    },
    meta: { noAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      default: login
    },
    meta: { noAuth: true },
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      default: register
    },
    meta: { noAuth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      default: dashBoard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/todo',
    name: 'Todo',
    components: {
      navBar: navBar,
      default: todo,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/haushalt',
    name: 'Haushalt',
    components: {
      navBar: navBar,
      default: haushalt,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/profil',
    name: 'Profil',
    components: {
      navBar: navBar,
      default: profil,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/erfolge',
    name: 'Erfolge',
    components: {
      navBar: navBar,
      default: erfolge,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/belohnung',
    name: 'Belohnung',
    components: {
      navBar: navBar,
      default: belohnung,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/404',
    name: '404',
    components: {
      navBar: null,
      default: null,
      footer: null
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    components: {
      navBar: null,
      default: null,
      footer: null
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  window.scrollTo(0, 0)
  window.onscroll = function () {}

  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(function (resolved) {
          config.loading = true
            next()
        })
        .catch(function (e) {
          console.log(e)
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .catch((e)=> {
          console.log(e)
        })
        .finally(function () {
          if (auth.isAuth) {
            config.loading = true
            next({
              path: '/dashboard'
            })
          } else {
            config.loading = true
            next()
          }
    })
  } else {
    auth.update()
    config.loading = true
    next()
  }
})

window.router = router

export default router
