<template>
  <main id="belohnung" class="bgcolor-beige">
    <section class="bgcolor-redbeige">
      <img src="assets/images/belohnungen-male.png" class="responsive-img" style="margin-bottom: -20px; max-width: 500px; margin: 0 auto; display: block;"/>
    </section>
    <section class="pad-16 " style="margin-top: -40px;">
      <div class="grid-12 br-8 pad-32 bgcolor-red color-white">
        <div class="col-sp-6 row-sp-12">
          Deine
          <h4>Belohnungsmünzen:</h4>
        </div>
        <div class="col-sp-6 row-sp-12 t-right">
          <div class="pad-4"></div>
          <h3>{{ userRewardPoints }} <img src="assets/images/coin.svg" style=" vertical-align: -4px; width: 24px;"/></h3>
        </div>
      </div>
    </section>
    <div class="grid-12 bgcolor-beige color-red">
      <div class="col-sp-6 row-sp-12">
        <button style="margin-left: 24px;" v-if="page == 'allRewards'" @click="page = 'myRewards';" class="button tiny bgcolor-red color-white br-8">Meine Belohnungen</button>
        <button style="margin-left: 24px;" v-else @click="page = 'allRewards';" class="button tiny bgcolor-red color-white br-8">Alle Belohnungen</button>
      </div>
      <div class="col-sp-6 row-sp-12 t-right" style="font-size: 0.8em;" @click="helpModal = true">
        Wie bekomme ich Münzen <img src="assets/images/questionmark.svg" style=" vertical-align: 4px; margin: 8px 24px -8px 0; width: 16px;"/>
      </div>
    </div>
    <section class="bgcolor-beige pad-16" v-if="page == 'allRewards'">
      <houseHoldRewards v-for="reward in houseHoldRewards" :reward="reward" :key="reward.id" @update="getRewardPointsById();getRewardsData();getRewardsById();page = 'myRewards'"/>
    </section>
    <section class="bgcolor-beige pad-16" v-else>
      <myhouseHoldRewards v-for="myreward in myhouseHoldRewards" :myreward="myreward" :key="myreward.id" @update="getRewardsById();"/>
    </section>
  </main>
  <transition name="modal" class="helpModal">
    <div class="vueModal" v-if="helpModal">
      <div class="vueModalOuter" @click="helpModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">
            <img src="assets/images/questionmark.svg" style="vertical-align: bottom;" class="width-32"/>
            Das Punktesystem
          </h2>
        </div>
        <div class="pad-8 t-center bgcolor-redbeige br-8">
          <div class="grid-12">
            <div class="col-sp-3 row-sp-12">
              <div class="pad-16"></div>
              <img src="assets/images/punktesystem-help.png" class="responsive-img"/>
            </div>
            <div class="col-sp-9 row-sp-12 t-left pad-8">
              <div class="pad-4"></div>
              <h4>Wie bekomme ich Punkte?</h4>
              <p>
                Für jede Aufgabe welche du von der Haushalts ToDo-List erledigst,
                erhälst du unterschiedlich viele Punkte. Zudem gibt es Extra Punkte, wenn
                du z. B. <router-link to="erfolge">Erfolge</router-link> freischaltest.
              </p>
              <div class="pad-8"></div>
              <h4>Was mache ich mit den Punkten?</h4>
              <p>
                Deine Punkte welche du in einem Monat sammelst, werden am Ende des Monats zu
                Münzen umgewandelt. Dabei bekommst du allerdings nur
                die Differenz deiner Punkte zum Punktestand vom "faulsten" Mitspieler.
                <br><br>
                Diese Münzen kannst du anschließend für <router-link to="belohnungen">Belohnungen</router-link> einlösen.
              </p>
            </div>
          </div>
        </div>
        <div class="t-left">
          <div class="pad-16"></div>
          <button class="button bgcolor-redbeige color-black br-8" @click="helpModal = false;">Zurück</button>
        </div>
      </div>
    </div>
  </transition>
  <houseHoldModal/>

</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import houseHoldModal from "@/components/modals/houseHoldModal";
import houseHoldRewards from "@/components/belohnungen/rewards";
import myhouseHoldRewards from "@/components/belohnungen/myrewards";

export default {
  name: "Belohnung",
  title: "Belohnung",
  components: {
    'houseHoldModal': houseHoldModal,
    'houseHoldRewards': houseHoldRewards,
    'myhouseHoldRewards': myhouseHoldRewards,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      helpModal: false,
      houseHoldRewards: '',
      myhouseHoldRewards: '',
      userRewardPoints: '',
      page: 'allRewards'
    }
  },
  mounted() {
    this.config.loading = false
    this.getRewardsData()
    this.getRewardsById()
    this.getRewardPointsById()
  },
  methods: {
    getRewardsData(){
      const _this = this
      this.axios.post(this.config.projectURL + 'belohnungen/getRewardsData')
          .then((response) => {
            if(response.status === 250){
              _this.houseHoldRewards = response.data
            }
          })
    },
    getRewardsById(){
      const _this = this
      this.axios.post(this.config.projectURL + 'belohnungen/getRewardsById')
          .then((response) => {
            console.log(response.data)
            if(response.status === 250){
              _this.myhouseHoldRewards = response.data
            }
          })
    },
    getRewardPointsById(){
      const _this = this
      this.axios.post(this.config.projectURL + 'belohnungen/getRewardPointsById')
          .then((response) => {
            if(response.status === 250){
              _this.userRewardPoints = response.data
            }
          })
    },
  }
}
</script>

<style>
main#belohnung{
  padding-bottom: 80px;
  padding-top: 0!important;
}
.helpModal .vueModalInner{
  background-color: #fffbec!important;
  width: calc(100% - 32px);
  max-width: 350px;
}
.helpModal .vueModalInner p{
  margin-top: 4px;
  color: rgba(0,0,0,0.4);
  font-size: 0.9em;
}
.helpModal .vueModalInner a{
  color: #e74966;
  text-decoration: none;
  font-weight: bold;
}
</style>