<template>
  <div class="taskitem" v-if="taskitem">
    <div class="grid-12">
      <div class="col-sp-2 row-sp-12 t-center">
        <img :src="'assets/images/icons/'+task.icon" class="bgcolor-redbeige width-16 image circular" style="padding: 4px 8px!important;"/>
      </div>
      <div class="col-sp-6 row-sp-12 t-left">
        <h4>{{ task.title }}</h4>
      </div>
      <div class="col-sp-4 row-sp-12 t-right">
        <button @click="taskId = task.id; editTask();" type="submit" class="button tiny bgcolor-red color-white">
          <img src="assets/images/edit-icon.svg" class="width-24 image">
        </button>
      </div>
    </div>
  </div>
  <div class="editTaskModal vueModalInner bottom" v-if="deleteModal">
    <div class="t-center pad-16">
      <h3 class="color-red">
        Aufgabe löschen?
      </h3>
      <p>Möchtest du die Aufgabe und die dazugehörigen ToDos wirklich löschen?</p>
    </div>
    <div class="grid-12">
      <div class="col-sp-6 row-sp-12 pad-8">
        <button class="button bgcolor-redbeige fluid color-black br-8" @click="editTaskModal = true; deleteModal = false;">Abbrechen</button>
      </div>
      <div class="col-sp-6 row-sp-12 pad-8">
        <button class="button bgcolor-red fluid color-white br-8" @click="deleteTask()">Löschen</button>
      </div>
    </div>
  </div>
  <div class="editTaskModal vueModalInner bottom" v-if="editTaskModal">
        <div class="t-center pad-16">
          <h2 class="color-red">
            Aufgabe bearbeiten
          </h2>
        </div>
        <form @submit.prevent="saveTask">
          <div class="pad-16 bgcolor-redbeige br-16">
            <div class="field simple">
              <label for="titel">Aufgabentitel</label>
              <input type="text" id="titel" name="titel" placeholder="Aufgabentitel" required v-model="editTitle" :value="editTitle">
            </div>
            <div class="field simple">
              <label for="points">Punkte</label>
              <input type="number" id="points" name="points" placeholder="Punkte" required v-model="editPoints" :value="editPoints">
            </div>
            <div class="field simple">
              <label>Icon Auswählen</label>
              <div class="grid-12">
                <div class="col-sp-4 row-sp-12">
                  <img :src="'assets/images/icons/'+editIcon" class="width-16 bgcolor-white circular image" style="padding: 4px 8px!important;">
                </div>
                <div class="col-sp-8 row-sp-12 t-right">
                  <button type="button" class="button tiny bgcolor-red color-white" @click="editTaskModal = false; selectEditIconModal = true;">Icon ändern</button>
                </div>
              </div>
            </div>
            <div>
              <div class="pad-4"></div>
              <span class="color-red" style="font-size: 0.8em;" @click="deleteModal = true; editTaskModal = false;">Aufgabe löschen</span>
            </div>
          </div>
          <div class="pad-16"></div>
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12 t-left">
              <button type="button" class="button bgcolor-redbeige color-black br-8" @click="editTaskModal = false; this.$emit('taskModalTrue'); taskitem = true;">Zurück</button>
            </div>
            <div class="col-sp-6 row-sp-12 t-right">
              <button type="submit" class="button bgcolor-red color-white br-8" v-if="editTitle.length > 0">Speichern</button>
            </div>
          </div>
        </form>
      </div>
      <div class="selectEditIconModal vueModalInner bottom" v-if="selectEditIconModal">
        <div class="t-center pad-16">
          <h2 class="color-white">
            Icon auswählen
          </h2>
        </div>
        <div class="grid-12">
          <div class="col-sp-3 row-sp-12 t-center">
            <img src="assets/images/icons/default.svg" @click="changeEditIcon('default.svg')" class="image circular width-32">
          </div>
          <div class="col-sp-3 row-sp-12 t-center">
            <img src="assets/images/icons/staubsauger.svg" @click="changeEditIcon('staubsauger.svg')" class="image circular width-32">
          </div>
        </div>
        <div class="pad-16"></div>
        <div class="t-center">
          <button type="button" class="button bgcolor-white color-red br-8" @click="selectEditIconModal = false; editTaskModal = true;" >Abbrechen</button>
        </div>
      </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "tasks",
  props: ["task"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      taskitem: true,
      editTaskModal: false,
      selectEditIconModal: false,
      deleteModal: false,
      editTitle: '',
      editPoints: 0,
      editIcon: 'default.svg',
      taskId: '',
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    changeEditIcon(icon){
      this.editIcon = icon,
      this.selectEditIconModal = false,
      this.editTaskModal = true
    },
    editTask(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.taskId)
      this.axios.post(this.config.projectURL + 'haushalt/getTaskDataById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.taskitem = false
              _this.editTitle = response.data.title
              _this.editPoints = response.data.points
              _this.editIcon = response.data.icon
              _this.editTaskModal = true
              this.$emit('taskModal')
            }
          })
    },
    deleteTask(){
      const _this = this
      const fD = new FormData()
      fD.append('taskId', this.taskId)
      console.log(this.taskId);
      this.axios.post(this.config.projectURL + 'haushalt/deleteTask', fD)
          .then((response) => {
            if(response.status === 250){
              _this.taskitem = true
              _this.taskId = ''
              _this.editTitle = ''
              _this.editPoints = ''
              _this.editIcon = ''
              _this.deleteModal = false
              this.$emit('update')
              this.$emit('taskModalTrue')
            }
          })
    },
    saveTask(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.taskId)
      fD.append('title', this.editTitle)
      fD.append('points', this.editPoints)
      fD.append('icon', this.editIcon)
      this.axios.post(this.config.projectURL + 'haushalt/saveTask', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.taskitem = true
              _this.taskId = ''
              _this.editTitle = ''
              _this.editPoints = ''
              _this.editIcon = ''
              _this.editTaskModal = false
              this.$emit('update')
              this.$emit('taskModalTrue')
            }
          })
    },
  }
}
</script>

<style scoped>
  .taskitem{
    background-color: #fde8db;
    padding: 8px 8px 4px 0px;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  .taskitem h4{
    margin-top: 4px;
  }
  .selectEditIconModal.vueModalInner{
    background-color: #e74966!important;
  }
  .selectEditIconModal.vueModalInner img.image{
    filter: brightness(100);
  }
</style>