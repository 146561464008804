<template>
  <div class="todoItem grid-12">
    <div class="col-sp-2 row-sp-12 pad-8">
      <img :src="'assets/images/icons/'+todo.icon" class="width-32"/>
    </div>
    <div class="col-sp-6 row-sp-12 pad-8">
      <h5>{{ todo.title }}</h5>
      <p>{{ todo.description }}</p>
      <span class="color-red">+ {{ todo.points }} Punkte</span>
    </div>
    <div class="col-sp-4 row-sp-12 pad-8 t-right">
      <button @click="checkId = todo.id; checkPoints = todo.points; checkModal = true;" class="bgcolor-red color-red inverted button tiny" style="margin-right: 8px;">
        <img src="assets/images/check.svg"/>
      </button>
      <button class="bgcolor-red button tiny">
        <img src="assets/images/more.svg"/>
      </button>
    </div>
  </div>
  <transition name="modal" class="checkModal">
    <div class="vueModal" v-if="checkModal">
      <div class="vueModalOuter" @click="checkModal = false;"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">Aufgabe erledigt?</h2>
          <p>Möchtes du die Aufgabe als erledigt makieren?</p>
          <div class="pad-16"></div>
          <div class="grid-12">
            <div class="col-sp-6 row-sp-12 pad-4">
              <button class="button bgcolor-redbeige fluid color-black br-8" @click="checkModal = false;">Abbrechen</button>
            </div>
            <div class="col-sp-6 row-sp-12 pad-4">
              <button class="button bgcolor-red fluid color-white br-8" @click="checkTodo();">Erledigt</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </transition>
  <transition name="modal" class="successModal">
    <div class="vueModal" v-if="successModal">
      <div class="vueModalOuter" @click="successModal = false;this.$emit('update');"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">HURRA!!</h2>
          <p>Du hast die Aufgabe erledigt und</p>
          <h3 class="color-red">{{ checkPoints }}</h3>
          <p>verdient. Mach weiter so!</p>
          <div class="pad-16"></div>
          <div class="col-sp-6 row-sp-12 pad-4">
            <button class="button bgcolor-red fluid color-white br-8" @click="successModal = false; checkPoints = '';this.$emit('update');">Okay</button>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "todos",
  props: ["todo"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      checkModal: false,
      successModal: false,
      checkId: '',
      checkPoints: '',
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    checkTodo(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.checkId)
      fD.append('points', this.checkPoints)
      this.axios.post(this.config.projectURL + 'todo/checkTodo', fD)
          .then((response) => {
            if(response.status === 250){
              this.checkModal = false
              this.checkId = ''
              this.successModal = true
            }
          })
    }
  }
}
</script>

<style scoped>
.todoItem{
  margin-bottom: 8px;
  background-color: #fffbec;
  border-radius: 8px;
  padding: 16px 4px;
}
.todoItem span{
  font-size: 0.9em;
}
.todoItem p{
  font-weight: normal;
  font-size: 0.8em;
  color: rgba(0,0,0,0.6);
}
.todoItem button.button{
  padding: 6px 6px 4px 6px;
  border: 1px solid #E66E6E;
}
.todoItem button img{
  filter: brightness(100);
  width: 20px;
}
.todoItem button.inverted img{
  filter: brightness(1);
  width: 20px;
}
</style>