<template>
  <header>
    <div class="grid-12">
      <div class="col-sp-6 row-sp-12 pad-8" style="padding-top: 16px!important;">
        <img @click="menuModal = true" id="menubtn" src="assets/images/menu-icon.svg" class="width-32"/>
      </div>
      <div class="col-sp-6 row-sp-12 pad-8 t-right">
        <router-link to="profil" @click="menuModal = false">
        <img :src="'assets/images/users/' + user.avatar" class="bgcolor-redbeige image width-48 circular"/>
        </router-link>
      </div>
    </div>
  </header>
  <transition name="modal" id="MenuModal">
    <div class="vueModal" v-if="menuModal">
      <div class="vueModalOuter" @click="menuModal = false"/>
      <div class="vueModalInner bottom">
        <div class="t-center pad-16">
          <h2 class="color-red">Menü</h2>
        </div>
        <div class="menuselection">
          <router-link @click="menuModal = false" to="profil" class="button color-red bgcolor-redbeige br-8">
            Mein Profil
          </router-link>
          <router-link @click="menuModal = false" to="haushalt" class="button color-red bgcolor-redbeige br-8">
            Mein Haushalt
          </router-link>
          <router-link to="premium" class="button bgcolor-red color-white br-8">
            Premium kaufen
          </router-link>
          <a @click="auth.logout()" class="button color-red bgcolor-redbeige br-8">
            Abmelden
          </a>
        </div>
        <div class="footer t-center">
          <router-link to="impressum">Impressum</router-link>
          <router-link to="datenschutz">Datenschutz</router-link>
          <router-link to="nutzungsbedingungen">Nutzungsbedingungen</router-link>
          <router-link to="agb">AGB</router-link>
          <p class="color-grey">Copyright &copy; 2022 - 20North Germany UG</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "NavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      menuModal: false,
    }
  }
}
</script>

<style scoped>
header{
  position: fixed;
  width: 100%;
  z-index: 99;
}
header img.image{
  border: 2px solid #ffffff;
}
header #menubtn{
  background-color: #e74966;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
}
header #menubtn img{
  filter: invert(1);
}
#MenuModal .vueModalInner{
  background-color: #fffbec!important;
  width: 100%;
  max-width: 350px;

}
.vueModal .vueModalInner a.button.color-white{
  color: #ffffff;
}
#MenuModal .menuselection .button{
  display: block;
  text-align: center;
  margin-bottom: 8px;

}
#MenuModal .vueModalInner .footer {
  margin-top: 32px;
}
#MenuModal .vueModalInner .footer a{
  font-size: 0.8em;
  color: #e74966;
  margin: 0 4px;
}
#MenuModal .vueModalInner .footer p{
  font-size: 0.8em;
  margin: 4px;
}
</style>